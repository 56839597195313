import React from 'react';
import '../style/home.css';
import '../style/style.css';
import BitcoinLogo from '../images/crypto-logos/svg/btc.svg'
import LitecoinLogo from '../images/crypto-logos/svg/ltc.svg'
import KadenaLogo from '../images/crypto-logos/svg/kda.svg'
import FluxLogo from '../images/crypto-logos/svg/flux.svg'
import MeowcoinLogo from '../images/crypto-logos/svg/mewc.svg'
import EthereumClassicLogo from '../images/crypto-logos/svg/etc.svg'
import KaspaLogo from '../images/crypto-logos/svg/kas.svg'
import RadiantLogo from '../images/crypto-logos/svg/rxd.svg'
import ErgoLogo from '../images/crypto-logos/svg/erg.svg'
import RavencoinLogo from '../images/crypto-logos/svg/rvn.svg'
import SiacoinLogo from '../images/crypto-logos/svg/sc.svg'
import VertcoinLogo from '../images/crypto-logos/svg/vtc.svg'

export default function Home() {
  return (
    <div className='home-background'>
      <div className='home-main'>
        <div className='title-container'>
          <div className='page-title h1'>POW BLOCKCHAIN EXPLORER</div>
          <div className='page-subtitle h1'>BLOCK DATA FROM 12 PROOF OF WORK BLOCKCHAINS</div>
          <div className='search-bar-container'>
            <input
              className='explorer-search-home no-highlight'
              type='text'
              id='explorersearch'
              placeholder='SEARCH TRANSACTIONS, ADDRESSES, AND BLOCKS'
              spellCheck='false'
            />
          </div>
        </div>
        <div className='volume-container'>
          <div className='h1'>24H TX VOLUME</div>
          <div className='tx-volume-graphic'>
            <div className='tx-volume-chart'></div>
            <div className='tx-volume-count h1'>24,192,192<br />TRANSACTIONS</div>
          </div>
          <div className='crypto-tx-volumes'>
            <div className='crypto-tx-volume-card'>
              <img className='crypto-tx-volume-card-logo no-highlight' src={BitcoinLogo} alt='btc' />
              <div className='crypto-tx-volume-card-tx-label h6'>102,292<br />35.192%</div>
            </div>
            <div className='crypto-tx-volume-card'>
              <img className='crypto-tx-volume-card-logo no-highlight' src={BitcoinLogo} alt='btc' />
              <div className='crypto-tx-volume-card-tx-label h6'>12,292<br />35.192%</div>
            </div>
            <div className='crypto-tx-volume-card'>
              <img className='crypto-tx-volume-card-logo no-highlight' src={BitcoinLogo} alt='btc' />
              <div className='crypto-tx-volume-card-tx-label h6'>12,292<br />35.192%</div>
            </div>
            <div className='crypto-tx-volume-card'>
              <img className='crypto-tx-volume-card-logo no-highlight' src={BitcoinLogo} alt='btc' />
              <div className='crypto-tx-volume-card-tx-label h6'>12,292<br />35.192%</div>
            </div>
            <div className='crypto-tx-volume-card'>
              <img className='crypto-tx-volume-card-logo no-highlight' src={BitcoinLogo} alt='btc' />
              <div className='crypto-tx-volume-card-tx-label h6'>12,292<br />35.192%</div>
            </div>
            <div className='crypto-tx-volume-card'>
              <img className='crypto-tx-volume-card-logo no-highlight' src={BitcoinLogo} alt='btc' />
              <div className='crypto-tx-volume-card-tx-label h6'>12,292<br />35.192%</div>
            </div>
          </div>
        </div>
        <div className='blockchain-container'>
          <div className='blockchain-container-title h1'>EXPLORE<br />BLOCKCHAIN</div>
          <img className='blockchain-container-crypto-logo no-highlight' src={BitcoinLogo} alt='btc' />
          <div className='blockchain-container-subtitle h1'>BTC</div>
        </div>
        <div className='blocks-container'>
          <div className='h1'>LATEST BLOCKS</div>
          <table className='blocks-table'>
            <thead>
              <tr>
                <th className='h3 coin-column'>COIN</th>
                <th className='h3 amount-column'>AMOUNT</th>
                <th className='h3 hash-column'>HASH</th>
                <th className='h3 time-column'>TIME</th>
                <th className='h3 confirmations-column'>CONFIRMATIONS</th>
                <th className='h3 fees-column'>FEES</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><img className='blocks-table-logo no-highlight' src={KaspaLogo} alt='kas' /></td>
                <td className='h3'>195.9 KAS</td>
                <td className='h3'>00B3...078A</td>
                <td className='h3'>1782</td>
                <td className='h3'>1782</td>
                <td className='h3'>1782</td>
              </tr>
              <tr>
                <td><img className='blocks-table-logo no-highlight' src={KadenaLogo} alt='kda' /></td>
                <td className='h3'>27.91 KDA</td>
                <td className='h3'>22A2...0000</td>
                <td className='h3'>1782</td>
                <td className='h3'>1782</td>
                <td className='h3'>1782</td>
              </tr>
              <tr>
                <td><img className='blocks-table-logo no-highlight' src={RadiantLogo} alt='rxd' /></td>
                <td className='h3'>0.237 ETC</td>
                <td className='h3'>0X3E...7EC7</td>
                <td className='h3'>1782</td>
                <td className='h3'>759 B</td>
                <td className='h3'>1782</td>
              </tr>
              <tr>
                <td><img className='blocks-table-logo no-highlight' src={MeowcoinLogo} alt='mewc' /></td>
                <td className='h3'>11,999 MEWC</td>
                <td className='h3'>1782</td>
                <td className='h3'>1782</td>
                <td className='h3'>1.66 KB</td>
                <td className='h3'>1782</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}