import React from 'react';
import '../style/cryptoinfopage.css';
import '../style/style.css';
import FluxLogo from '../images/crypto-logos/svg/flux.svg'

function Flux() {
    return (
        <div className='crypto-info-background flux-background'>
            <div className='main-page'>
                <div className='logo-container'>
                    <img className='info-page-crypto-logo' src={FluxLogo} alt='flux-logo' />
                </div>
                <div className='title-container'>
                    <div className='page-title h1'>FLUX EXPLORER</div>
                    <div className='page-subtitle h1'>FLUX IS A CRYPTOCURRENCY LAUNCHED IN 2018 THAT UTILIZES THE ZELHASH HASHING ALGORITHM</div>
                </div>
                <div className='latest-block block-1'>
                    <div className='latest-block-title h1'>2,509,851</div>
                    <div className='latest-block-title h3'>2 MINS AGO</div>
                    <div /><div />
                    <div className='latest-block-divider' />
                    <div /><div />
                    <div className='latest-block-title h2'>128 TXS</div>
                    <div className='latest-block-title h2'>21,875 KDA</div>
                </div>
                <div className='latest-block block-2'>
                    <div className='latest-block-title h1'>2,509,850</div>
                    <div className='latest-block-title h3'>4 MINS AGO</div>
                    <div /><div />
                    <div className='latest-block-divider' />
                    <div /><div />
                    <div className='latest-block-title h2'>85 TXS</div>
                    <div className='latest-block-title h2'>24,797 KDA</div>
                </div>
                <div className='latest-block block-3'>
                    <div className='latest-block-title h1'>2,509,849</div>
                    <div className='latest-block-title h3'>6 MINS AGO</div>
                    <div /><div />
                    <div className='latest-block-divider' />
                    <div /><div />
                    <div className='latest-block-title h2'>19 TXS</div>
                    <div className='latest-block-title h2'>16,644 KDA</div>
                </div>
                <div className='mempool-card'>
                    <div className='mempool-title h1'>MEMPOOL<br />USAGE</div>
                </div>
                <div className='info-card info-card-1'>
                    <div className='info-card-title h2'>TRANSACTIONS<br />170,098,991</div>
                </div>
                <div className='info-card info-card-2'>
                    <div className='info-card-title h2'>ADDRESSES<br />8,839,018</div>
                </div>
                <div className='info-card info-card-3'>
                    <div className='info-card-title h2'>HASHRATE<br />811.14 TH/S</div>
                </div>
                <div className='info-card info-card-4'>
                    <div className='info-card-title h2'>DIFFICULTY<br />25,873,511</div>
                </div>
                <div className='info-chart'></div>
            </div>
        </div>
    );
}

export default Flux;