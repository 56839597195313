import React from 'react'
import { Link } from 'react-router-dom';
import '../style/style.css';
import '../style/blockchains.css';
import BitcoinLogo from '../images/crypto-logos/svg/btc.svg'
import LitecoinLogo from '../images/crypto-logos/svg/ltc.svg'
import KadenaLogo from '../images/crypto-logos/svg/kda.svg'
import FluxLogo from '../images/crypto-logos/svg/flux.svg'
import MeowcoinLogo from '../images/crypto-logos/svg/mewc.svg'
import EthereumClassicLogo from '../images/crypto-logos/svg/etc.svg'
import KaspaLogo from '../images/crypto-logos/svg/kas.svg'
import RadiantLogo from '../images/crypto-logos/svg/rxd.svg'
import ErgoLogo from '../images/crypto-logos/svg/erg.svg'
import RavencoinLogo from '../images/crypto-logos/svg/rvn.svg'
import SiacoinLogo from '../images/crypto-logos/svg/sc.svg'
import VertcoinLogo from '../images/crypto-logos/svg/vtc.svg'

export default function blockchains() {
    return (
        <div className='blockchains'>
            <Link className='blockchains-link' to='/bitcoin'>
                <div className='blockchains-button blockchains-bitcoin'>
                    <img className='crypto-logo' src={BitcoinLogo} alt='btc' />
                    <div className='blockchains-label'>BITCOIN</div>
                </div>
            </Link>
            <Link className='blockchains-link' to='/litecoin'>
                <div className='blockchains-button blockchains-litecoin'>
                    <img className='crypto-logo' src={LitecoinLogo} alt='ltc' />
                    <div className='blockchains-label'>LITECOIN</div>
                </div>
            </Link>
            <Link className='blockchains-link' to='/kadena'>
                <div className='blockchains-button blockchains-kadena'>
                    <img className='crypto-logo' src={KadenaLogo} alt='kda' />
                    <div className='blockchains-label'>KADENA</div>
                </div>
            </Link>
            <Link className='blockchains-link' to='/flux'>
                <div className='blockchains-button blockchains-flux'>
                    <img className='crypto-logo' src={FluxLogo} alt='flux' />
                    <div className='blockchains-label'>FLUX</div>
                </div>
            </Link>
            <Link className='blockchains-link' to='/meowcoin'>
                <div className='blockchains-button blockchains-meowcoin'>
                    <img className='crypto-logo' src={MeowcoinLogo} alt='mewc' />
                    <div className='blockchains-label'>MEOWCOIN</div>
                </div>
            </Link>
            <Link className='blockchains-link' to='/ethereum-classic'>
                <div className='blockchains-button blockchains-ethereumclassic'>
                    <img className='crypto-logo' src={EthereumClassicLogo} alt='etc' />
                    <div className='blockchains-label'>ETHEREUM<br />CLASSIC</div>
                </div>
            </Link>
            <Link className='blockchains-link' to='/kaspa'>
                <div className='blockchains-button blockchains-kaspa'>
                    <img className='crypto-logo' src={KaspaLogo} alt='kas' />
                    <div className='blockchains-label'>KASPA</div>
                </div>
            </Link>
            <Link className='blockchains-link' to='/radiant'>
                <div className='blockchains-button blockchains-radiant'>
                    <img className='crypto-logo' src={RadiantLogo} alt='rxd' />
                    <div className='blockchains-label'>RADIANT</div>
                </div>
            </Link>
            <Link className='blockchains-link' to='/ergo'>
                <div className='blockchains-button blockchains-ergo'>
                    <img className='crypto-logo' src={ErgoLogo} alt='erg' />
                    <div className='blockchains-label'>ERGO</div>
                </div>
            </Link>
            <Link className='blockchains-link' to='/ravencoin'>
                <div className='blockchains-button blockchains-ravencoin'>
                    <img className='crypto-logo' src={RavencoinLogo} alt='rvn' />
                    <div className='blockchains-label'>RAVENCOIN</div>
                </div>
            </Link>
            <Link className='blockchains-link' to='/siacoin'>
                <div className='blockchains-button blockchains-siacoin'>
                    <img className='crypto-logo' src={SiacoinLogo} alt='sc' />
                    <div className='blockchains-label'>SIACOIN</div>
                </div>
            </Link>
            <Link className='blockchains-link' to='/vertcoin'>
                <div className='blockchains-button blockchains-vertcoin'>
                    <img className='crypto-logo' src={VertcoinLogo} alt='vtc' />
                    <div className='blockchains-label'>VERTCOIN</div>
                </div>
            </Link>
        </div>
    )
}