import React from 'react';
import '../style/navbar.css';
import '../style/style.css';
import gearIcon from '../images/icons/gear.svg';
import logo from '../images/icons/merqury_logo.svg';
import { Link } from 'react-router-dom';

export default function Navbar({ setShowBlockchains }) {

    function toggleBlockchains() {
        setShowBlockchains(prevShowBlockchains => !prevShowBlockchains);
    }

    return (
        <div className='navbar no-highlight'>
            <a href='https://merqury.io/'>
                <img className='navbar-logo no-highlight' src={logo} alt='logo' />
            </a>
            <Link className='title-link' to='/'>
                <div className='title h1'>MERQURY EXPLORER</div>
            </Link>
            <input
                className='explorer-search no-highlight'
                type='text'
                id='explorersearch'
                placeholder='SEARCH TRANSACTIONS, ADDRESSES, AND BLOCKS'
                spellCheck='false'
            />
            <button onClick={toggleBlockchains} className='title h1'>
                BLOCKCHAINS
            </button>
            <button>
                <img src={gearIcon} alt='gear' className='settings-icon no-highlight' />
            </button>
        </div >
    );
}