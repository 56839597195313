import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Navbar from './components/navbar';
import Blockchains from './components/blockchains';
import Home from './pages/home';
import Bitcoin from './pages/bitcoin';
import Litecoin from './pages/litecoin';
import Kadena from './pages/kadena';
import Flux from './pages/flux';
import Meowcoin from './pages/meowcoin';
import EthereumClassic from './pages/ethereum-classic';
import Kaspa from './pages/kaspa';
import Radiant from './pages/radiant';
import Ergo from './pages/ergo';
import Ravencoin from './pages/ravencoin';
import Siacoin from './pages/siacoin';
import Vertcoin from './pages/vertcoin';

export default function App() {
  const [showBlockchains, setShowBlockchains] = useState(false);

  return (
    <Router>
      <Navbar setShowBlockchains={setShowBlockchains} />
      {showBlockchains && <Blockchains />}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/bitcoin' element={<Bitcoin />} />
        <Route path='/litecoin' element={<Litecoin />} />
        <Route path='/kadena' element={<Kadena />} />
        <Route path='/flux' element={<Flux />} />
        <Route path='/meowcoin' element={<Meowcoin />} />
        <Route path='/ethereum-classic' element={<EthereumClassic />} />
        <Route path='/kaspa' element={<Kaspa />} />
        <Route path='/radiant' element={<Radiant />} />
        <Route path='/ergo' element={<Ergo />} />
        <Route path='/ravencoin' element={<Ravencoin />} />
        <Route path='/siacoin' element={<Siacoin />} />
        <Route path='/vertcoin' element={<Vertcoin />} />
      </Routes>
    </Router>
  );
}